<template>
  <NuxtLink
    :to="to"
    :class="[icon]"
    class="header-nav-item"
  >
    <LazyBadge v-if="badge" size="xs" border="xs">{{ badge }}</LazyBadge>
    <span v-if="label" class="header-nav-item-text">
      {{ label }}
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

const LazyBadge = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/atoms/Badge/ABadge.vue')
)

defineProps({
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  badge: {
    type: [String, Number],
    default: ''
  }
})
</script>

<style lang="postcss">
.header-nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.75rem;
  height: 3.5rem;
  padding: var(--spacer-4xs) 0;
  color: var(--color-neutral-700);
  white-space: nowrap;
  user-select: none;

  .badge {
    position: absolute;
    top: var(--spacer-5xs);
    left: 50%;
    width: auto;
  }

  .header-nav-item-text {
    overflow: hidden;
    max-width: 76px;
    height: 20px;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.25rem;
    text-overflow: ellipsis;
  }

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      color: var(--color-blue-800);
      cursor: pointer;

      &.i-user-header::before {
        background-image: url("/assets/icons/general.svg#user--hover");
      }

      &.i-favorite-header::before {
        background-image: url("/assets/icons/general.svg#favorite-header--hover");
      }

      &.i-cart-header::before {
        background-image: url("/assets/icons/general.svg#cart--hover");
      }
    }
  }
}
</style>
